export default gql`
  fragment BlocksFragment on page_blocks {
    id
    collection
    item {
      ... on block_columns {
        headline
        id
      }
      ... on block_hero {
        content
        headline
        title
        id
        type
        background_color
        image {
          id
          title
          width
          height
          description
          tags
        }
        video {
          id
          title
          width
          height
          description
          tags
        }
      }
      ... on block_headline {
        headline
        id
        variant
      }
      ... on block_cta {
        content
        headline
        title
        id
      }
      ... on block_divider {
        title
        id
      }
      ... on block_html {
        id
        raw_html
      }
      ... on block_logocloud {
        headline
        id
        title
      }
      ... on block_quote {
        content
        subtitle
        title
        id
      }
      ... on block_richtext {
        content
        id
      }
      ... on block_steps {
        alternate_image_position
        headline
        show_step_numbers
        title
        id
      }
      ... on block_imagebox {
        title
        content
        image {
          id
          title
          width
          height
        }
        id
        variant
        portrait_image {
          title
          description
          id
        }
      }
      ... on block_latestinsights {
        type
        length
        id
      }
      ... on block_video {
        headline
        title
        type
        video_url
        id
      }
      ... on block_testimonials {
        headline
        title
        id
      }
      ... on block_gallery {
        headline
        title
        id
      }
      ... on block_form {
        title
        headline
        id
      }
      ... on block_faqs {
        faqs
        headline
        id
        title
        alignment
      }
      ... on block_services {
        id
        services {
          id
          status
          sort
          slug
          name
          title
          short_title
          description
          background_image {
            id
            title
            description
            tags
          }
          logo {
            id
            title
            description
            tags
          }
          service_offers {
            id
            sort
            name
            why_us
            headline
            service_offer_items {
              id
              sort
              title
              description
            }
          }
        }
      }
      ... on block_animated_headline {
        id
        headline
      }
      ... on block_section_image {
        id
        title
        content
        image_landscape {
          id
          description
          title
        }
        image_vertical {
          id
          title
          description
        }
      }
      ... on block_section_text {
        id
        title
        subtitle
        content
      }
      ... on block_locations {
        id
        title
        content
        regions {
          name
          offices {
            city
            address
            contact_number
            pin_details
            id
          }
        }
      }
      ... on block_team {
        content
        title
        id
        members {
          id
          information: team_id {
            bio
            id
            job_title
            name
            image {
              id
              title
              description
            }
          }
        }
      }
      ... on block_insights {
        length
        id
      }
      ... on block_last_insight {
        id
        label
      }
      ... on block_latestinvestments {
        id
        length
      }
      ... on block_manifesto {
        id
        title
        content
      }
      ... on block_brands {
        id
        title
        logos {
          file: directus_files_id {
            id
            title
            width
            height
            description
            tags
          }
        }
      }
      ... on block_single_page {
        id
        title
        content
      }
    }
  }
`;
